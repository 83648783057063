import { css } from 'styled-components';

export const breakpoints = {
  sm: '480px',
  l: '828px',
  tablet: '1024px',
  xl: '1440px',
};

export const fonts = {
  fontPrimaryRegular: 'gt-america-extended-regular',
  fontPrimaryBold: 'gt-america-extended-bold',
  fontSecondaryBlack: 'gt-america-expanded-black',
  fontSecondaryBold: 'gt-america-expanded-bold',
  fontPrimaryNameFolder: 'gt-america-extended',
  fontSecondaryNameFolder: 'gt-america-expanded',
};

export const getTypographyStyles = {
  heading: (withMargin = true, font = fonts.fontPrimaryBold) => css`
    ${withMargin
    && css`
      margin-bottom: 32px;
    `}

    font: 32px/48px ${font};
  `,
  heading1: (font = fonts.fontPrimaryBold) => css`
    margin-bottom: 16px;
    font: 24px/36px ${font};
  `,
  heading2: (font = fonts.fontPrimaryBold) => css`
    margin-bottom: 8px;
    font: 20px/30px ${font};
  `,
  heading3: (font = fonts.fontPrimaryBold) => css`
    margin-bottom: 8px;
    font: 16px/24px ${font};
  `,
  heading4: (font = fonts.fontPrimaryBold) => css`
    margin-bottom: 8px;
    font: 14px/21px ${font};
  `,
  body: (withMargin = false, font = fonts.fontPrimaryRegular) => css`
    ${withMargin
    && css`
      margin-bottom: 16px;
    `};
    font: 14px/21px ${font};
  `,
  secondaryText: (font = fonts.fontPrimaryRegular) => css`
    margin-bottom: 16px;
    font: 12px/18px ${font};
  `,
  tertiaryText: (font = fonts.fontPrimaryRegular) => css`
    margin-bottom: 16px;
    font: 10px/15px ${font};
  `,
};

export const colors = {
  colorGray: '#808080',
  colorMutedWhite: '#E4E4E4',
  colorWhite: '#ffffff',
  colorBlack: '#000000',
  colorAlto: '#d7d7d7',
  colorScarlet: '#e81f00',
  colorGallery: '#eeeeee',
  colorDoveGrey: '#666666',
  colorLiteGrey: '#696969',
  colorForestGreen: '#266d1c',
  colorCeruleanBlue: '#2960af',
  colorSilverChalice: '#afafaf',
  colorSchoolBusYellow: '#ffda0a',
  colorScarletHeader: '#e81f00',
};

export const widths = {
  sm: '480px',
  l: '700px',
  tablet: '960px',
  xl: '1312px',
};

export const indents = {
  sm: '32px',
  l: '64px',
  tablet: '32px',
  xl: '64px',
};
