import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';

import InstagramIcon from '@icons/instagram.svg';
import TwitterIcon from '@icons/twitter.svg';
import DepopLogoIcon from '@icons/depop-logo.svg';
import YoutubeIcon from '@icons/youtube-icon.svg';
import FacebookIcon from '@icons/facebook-icon.svg';
import AppStoreIcon from '@icons/download-app-store-icon.svg';
import GooglePlayIcon from '@icons/get-on-google-play-icon.svg';
import moment from 'moment';
import { colors, fonts } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';
import FooterInfoSection from './components/FooterInfoSection';
import { useCheckIsMobile } from './useCheckIsMobile';

const StyledContainer = styled.footer`
  --footer-max-row-width: 3000px;
  --footer-row-padding: 64px;
  --footer-custom-breakpoint: 1203px;

  display: inline;
  height: auto;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 44px 0 0;
  margin-top: auto;
  background: ${colors.colorBlack};
  color: ${colors.colorWhite};

  ${screen.l(css`
    height: auto;
    padding-bottom: 0;
  `)}

  & * {
    box-sizing: border-box;
  }
`;

const StyledLogoNLinksWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  ${screen.tablet(css`
    flex-direction: column;
    align-items: flex-start;
  `)};

  ${screen.sm(
    css`
      align-items: center;
    `,
  )};
`;

const StyledLogo = styled(Link)`
  margin-right: 71px;

  ${screen.xl(
    css`
      margin-right: 24px;
    `,
  )};

  ${screen.tablet(
    css`
      margin-bottom: 12px;
    `,
  )};

  ${screen.sm(css`
    margin-right: 0;
    margin-bottom: 30px;
  `)};
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 108px;
  margin-bottom: 4px;

  ${screen.xl(
    css`
      margin-right: 24px;
    `,
  )};

  ${screen.tablet(css`
    margin-left: -4px;
  `)};

  ${screen.sm(css`
    margin-right: 0;
    margin-bottom: 24px;
  `)};
`;

const StyledIconWrapper = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;

  margin-right: 18px;
  cursor: pointer;
  fill: ${colors.colorMutedWhite};
  transition: fill 0.15s;

  ${screen.tablet(css`
    align-items: flex-end;
    margin-right: 8px;
  `)};

  ${screen.sm(css`
    align-items: flex-end;
    margin-right: 10px;
  `)};

  &:hover {
    fill: ${colors.colorWhite};
  }

  svg {
    width: 21px;
    height: 21px;
  }
`;

const StyledLinksRow = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--footer-max-row-width);
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--footer-row-padding);
  margin: 0 auto;

  ${screen.xl(css`
    --footer-max-row-width: 100%;
    --footer-row-padding: 32px;
    justify-content: space-between;
  `)};

  ${screen.l(css`
    align-items: stretch;
    margin-bottom: 30px;
  `)};

  ${screen.sm(css`
    --footer-row-padding: 16px;
    flex-direction: column;
    align-items: center;
  `)};
`;

const StyledContentRow = styled.div`
  display: flex;
  width: 100%;
  max-width: var(--footer-max-row-width);
  align-items: flex-start;
  justify-content: space-between;

  padding: 0 var(--footer-row-padding);
  margin: 0 auto;

  ${screen.xl(
    css`
      --footer-row-padding: 32px;
    `,
  )};

  ${screen.sm(css`
    --footer-row-padding: 16px;
    flex-direction: column;
  `)};
`;

const StyledFooterInfoSectionsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;

  ${screen.l(css`
    align-items: center;
    justify-content: flex-end;
  `)};

  ${screen.sm(css`
    flex-direction: column;
    align-items: center;
  `)};
`;

const StyledAppsLinksWrapper = styled.div`
  ${screen.sm(css`
    display: flex;
    width: 100%;
    justify-content: center;
  `)};
`;
const StyledInstallLinkWrapper = styled.a`
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;

  ${screen.sm(css`
    &:not(:last-child) {
      margin-right: 10px;
    }
  `)};

  &:not(:last-child) {
    margin-right: 33px;
  }
`;

const StyledCopyRightWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 28px;
  border-top: 1px solid #333333;
  margin-top: 34px;

  ${screen.l(
    css`
      margin-top: 30px;
    `,
  )};

  ${screen.sm(css`
    flex-direction: column;
    align-items: center;
    padding-top: 28px;
  `)};
`;
const StyledCopyRight = styled.span`
  color: ${colors.colorGray};

  ${screen.sm(
    css`
      margin-bottom: 23px;
    `,
  )};
`;

const StyledBottomLink = styled(Link)`
  color: ${colors.colorGray};
  cursor: pointer;
  font: 14px/21px ${fonts.fontPrimaryRegular};
  text-decoration: none;
  transition: color 0.15s;

  &:not(:last-child) {
    margin-right: 64px;
  }

  ${screen.l(css`
    &:not(:last-child) {
      margin-right: 32px;
    }
  `)};

  ${screen.sm(css`
    margin-bottom: 12px;

    &:not(:last-child) {
      margin-right: 24px;
    }
  `)};

  &:hover {
    color: ${colors.colorMutedWhite};
    text-decoration: underline;
  }
`;

const StyledBottomLinksWrapper = styled.div`
  display: flex;

  ${screen.sm(css`
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  `)};
`;

/**
 * Footer
 */
const Footer = () => {
  const meta = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menuLinks {
            label
            url
            subLinks {
              label
              url
            }
          }
        }
      }
    }
  `);
  const bottomLinks = [
    {
      href:
        'https://depophelp.zendesk.com/hc/en-gb/articles/360001792147-Privacy-Policy-',
      label: 'Privacy',
    },
    {
      href:
        'https://depophelp.zendesk.com/hc/en-gb/articles/360001773148-Terms-of-Service',
      label: 'Terms',
    },
    {
      href: 'https://depophelp.zendesk.com/hc/en-gb',
      label: 'Support',
    },
    {
      href:
        'https://depophelp.zendesk.com/hc/en-gb/articles/360026370634-Community-Guidelines-',
      label: 'Safety',
    },
    {
      href:
        'https://s22.q4cdn.com/941741262/files/doc_downloads/governance_documents/2023/modern-slavery-statement-feb23-final.pdf',
      label: 'Modern Slavery',
    },
    {
      href:
        'https://assets.depop.com/web/assets/DEPOP_Supplier_Code_of_Conduct.pdf',
      label: 'Supplier Code of Conduct'
    }
  ];

  const isMobile = useCheckIsMobile();

  const linksMock = {
    depopMainSite: 'https://www.depop.com/',
    appStore:
      'https://apps.apple.com/gb/app/depop/id518684914?_branch_match_id=865218625507980102',
    googlePlay:
      'https://play.google.com/store/apps/details?id=com.depop&hl=en_GB&_branch_match_id=865218625507980102',
    facebook: isMobile
      ? 'https://m.facebook.com/depop'
      : 'https://www.facebook.com/depop',
    instagram: isMobile
      ? 'https://www.instagram.com/depop/?hl=en'
      : 'https://www.instagram.com/depop/',
    twitter: isMobile
      ? 'https://mobile.twitter.com/depop/'
      : 'https://twitter.com/depop/',
    youtube: isMobile
      ? 'https://m.youtube.com/channel/UC0w1tGdbwerFIaAGS0uGUbA'
      : 'https://www.youtube.com/channel/UC0w1tGdbwerFIaAGS0uGUbA',
  };
  const currentYear = moment().year();

  const socialMediaIcons = [
    {
      href: linksMock.facebook,
      'aria-label': 'Go to our facebook page',
      title: 'Go to our facebook page',
      icon: <FacebookIcon />,
    },

    {
      href: linksMock.instagram,
      'aria-label': 'Go to our instagram page',
      title: 'Go to our instagram page',
      icon: <InstagramIcon />,
    },

    {
      href: linksMock.twitter,
      'aria-label': 'Go to our twitter page',
      title: 'Go to our twitter page',
      icon: <TwitterIcon />,
    },

    {
      href: linksMock.youtube,
      'aria-label': 'Go to our youtube channel',
      title: 'Go to our youtube channel',
      icon: <YoutubeIcon />,
    },
  ];

  const handleResetFocus = () => {
    if (document.activeElement !== document.body) document.activeElement.blur();
  };

  return (
    <StyledContainer>
      <StyledLinksRow>
        <StyledLogoNLinksWrapper>
          <StyledLogo
            onClick={handleResetFocus}
            aria-label="Depop logo"
            target="_blank"
            rel="noopener noreferrer"
            title="Go to home page"
            to={linksMock.depopMainSite}
          >
            <DepopLogoIcon />
          </StyledLogo>
          <StyledIconsWrapper>
            {socialMediaIcons.map(({ icon, ...iconProps }) => (
              <StyledIconWrapper
                key={iconProps.href}
                target="_blank"
                rel="noopener noreferrer"
                {...iconProps}
              >
                {icon}
              </StyledIconWrapper>
            ))}
          </StyledIconsWrapper>
        </StyledLogoNLinksWrapper>
        <StyledFooterInfoSectionsWrapper>
          {meta.site.siteMetadata.menuLinks.map((route) => (
            <FooterInfoSection
              onClick={handleResetFocus}
              key={route.label}
              {...route}
            />
          ))}
        </StyledFooterInfoSectionsWrapper>
      </StyledLinksRow>
      <StyledContentRow>
        {/* Language Dropdown */}
        <div />
        <StyledAppsLinksWrapper>
          <StyledInstallLinkWrapper
            target="_blank"
            rel="noopener noreferrer"
            href={linksMock.appStore}
          >
            <AppStoreIcon />
          </StyledInstallLinkWrapper>
          <StyledInstallLinkWrapper
            target="_blank"
            rel="noopener noreferrer"
            href={linksMock.googlePlay}
          >
            <GooglePlayIcon />
          </StyledInstallLinkWrapper>
        </StyledAppsLinksWrapper>
      </StyledContentRow>
      <StyledContentRow>
        <StyledCopyRightWrapper>
          <StyledCopyRight>
            © Depop
            {' '}
            {currentYear}
          </StyledCopyRight>
          <StyledBottomLinksWrapper>
            {bottomLinks.map((link) => (
              <StyledBottomLink
                key={link.href}
                target="_blank"
                rel="noopener noreferrer"
                to={link.href}
              >
                {link.label}
              </StyledBottomLink>
            ))}
          </StyledBottomLinksWrapper>
        </StyledCopyRightWrapper>
      </StyledContentRow>
    </StyledContainer>
  );
};

export default Footer;
